import AddImage1 from "../../assets/img/add/1.png";
export const sliderData = [
  {
    image: "https://cc-west-usa.oss-accelerate.aliyuncs.com/67930c86-10c3-4d49-8791-ee37b44a848d.jpg",
    heading: "Hiking Essentials",
    desc: "Up to 30% off on all onsale proucts.",
  },
  {
    image: "https://cc-west-usa.oss-accelerate.aliyuncs.com/f7f796b1-20e4-4221-9099-c22e45e9fa17.jpg",
    heading: "Camping Gear",
    desc: "Up to 30% off on all onsale proucts.",
  },
  {
  
    image: "https://cc-west-usa.oss-accelerate.aliyuncs.com/971cc4cd-3e2c-4b14-9d98-a155f42d4ba6.jpg",
    heading: "Adventure Cycling",
    desc: "Up to 30% off on all onsale proucts.",
  },
  {
    image: "https://cc-west-usa.oss-accelerate.aliyuncs.com/6eb70973-b976-4540-bf35-ff5cee16e721.jpg",
    heading: "Outdoor Apparel",
    desc: "Up to 30% off on all onsale proucts.",
  },
];
