import React from "react";
import Product from "../../components/product/Product";
import styles from "./Catalogue.module.scss";

const Catalogue = () => {

  return (
    <div className={styles.slid}>
      <Product />
    </div>
  );
};

export default Catalogue;
