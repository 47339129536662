import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";

const useFetchDocument = (documentID) => {
  const [document, setDocument] = useState(null);
//test
  const getDocument = () => {
    try {
      axios.get('https://developers.cjdropshipping.com/api2.0/v1/product/query?pid='+documentID, {
    headers: {
      "CJ-Access-Token": 'eyJhbGciOiJIUzI1NiJ9.eyJqdGkiOiIxMDYxNyIsInR5cGUiOiJBQ0NFU1NfVE9LRU4iLCJzdWIiOiJicUxvYnFRMGxtTm55UXB4UFdMWnltWWFTSmh4VGM3RHdYOHpKRStmN2pKZGNvZUdWMDVwb3o4OHBsQW1BU3VIMG9HWnVnREIzOEFQbEUvcmZDMktuWktsNi84Qk5VQndtL0FiYWxHMk9IZ05aUHNrODF4TVlaRm9LTG9GblF5WDFPSHpYMlZuWHBFMlp1dHJjalI1V1QrS3hEb0xpZ2ZpWElrNHhORUxoMlRrSFpDaVArN3UzNmVGb3lhVlMwSEJYSTRRQUp0bk5DTk5vdTVpZStYYUVvM24wMUxadkU4RDFobWVaMW5LVlNDczV1cEpTQzFjVTF3WDJEbXJWd3J3cU1ZNWpDUUxLa2dtWDRHdXZqOURXZng4UW81dFp6Q1UrT1dSY3M3NGJsQzJFMmRHa2Qva3FVYjVxK3hPazBKciJ9.wvWxle-rAEaKBKHb2CkaHMk5BTqslrNRKV1jd2DXXCU'
    }   
  })
  .then(res => {
    console.log(res.data.data);
    setDocument(res.data.data);
  });
    } catch (error) {
      toast.error("Document not found");
    }
  };

  useEffect(() => {
    getDocument();
  }, []);

  return { document };
};

export default useFetchDocument;
