import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
export const firebaseConfig = {
  apiKey: "AIzaSyBKSgjYRZmliPmeT-KgMXsCyh-toJXtxo8",
  authDomain: "react-firebase-e73a9.firebaseapp.com",
  databaseURL: "https://react-firebase-e73a9.firebaseio.com",
  projectId: "react-firebase-e73a9",
  storageBucket: "react-firebase-e73a9.appspot.com",
  messagingSenderId: "516833256807",
  appId: "1:516833256807:web:d81d54140bfb391df1fbc2",
  measurementId: "G-DGF4WNHND7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);

export default app;
