import React, { useEffect, useState } from "react";
import styles from "../product/productList/ProductList.module.scss";
//import styles from "../product/productItem/ProductItem.module.scss";
import { toast } from "react-toastify";
import axios from "axios";
import { Link } from "react-router-dom";
//import Card from "../../card/Card";
import ProductItem from "../product/productItem/ProductItem";
import Card from "../card/Card";

export default function Pricing() {

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [grid, setGrid] = useState(true);
  const getCollection = () => {
    setIsLoading(true);
   
       axios.get('https://developers.cjdropshipping.com/api2.0/v1/product/list?categoryId=EA851596-F20F-4AA5-8869-4BB5CA1968DC&pageNum=8&pageSize=15', {
        headers: {
          "CJ-Access-Token": 'eyJhbGciOiJIUzI1NiJ9.eyJqdGkiOiIxMDYxNyIsInR5cGUiOiJBQ0NFU1NfVE9LRU4iLCJzdWIiOiJicUxvYnFRMGxtTm55UXB4UFdMWnltWWFTSmh4VGM3RHdYOHpKRStmN2pKZGNvZUdWMDVwb3o4OHBsQW1BU3VIMG9HWnVnREIzOEFQbEUvcmZDMktuWktsNi84Qk5VQndtL0FiYWxHMk9IZ05aUHNrODF4TVlaRm9LTG9GblF5WDFPSHpYMlZuWHBFMlp1dHJjalI1V1QrS3hEb0xpZ2ZpWElrNHhORUxoMlRrSFpDaVArN3UzNmVGb3lhVlMwSEJYSTRRQUp0bk5DTk5vdTVpZStYYUVvM24wMUxadkU4RDFobWVaMW5LVlNDczV1cEpTQzFjVTF3WDJEbXJWd3J3cU1ZNWpDUUxLa2dtWDRHdXZqOURXZng4UW81dFp6Q1UrT1dSY3M3NGJsQzJFMmRHa2Qva3FVYjVxK3hPazBKciJ9.wvWxle-rAEaKBKHb2CkaHMk5BTqslrNRKV1jd2DXXCU'
        }   
      })
      .then(res => {
        setData(res.data.data.list);
        setIsLoading(false);
        console.log(data)
      })
      .catch (err => {
        setIsLoading(false);
        toast.error(err.message);
      })
    //}
  };

  useEffect(() => {
    getCollection();
  }, []);

//  const shortenText = (text, n) => {
 //   if (text.length > n) {
 //     const shortenedText = text.substring(0, n).concat("...");
 //     return shortenedText;
  //  }
 //   return text;
  //};

  return (
    <>
      <h2 className="font40 extraBold textColor">Most Ordered Products</h2>
      <br/>  <br/>
      <div className={styles.grid}>
      {data.map((product) => {
              return (
                <div key={product.pid}>
                  <ProductItem {...product} grid={grid} product={product} />
                </div>
              );
            })}
      </div>
    </>
   );
}




