import { addDoc, collection, doc, setDoc, Timestamp } from "firebase/firestore";
import { db } from "../../../firebase/config";
//import styles from "./Axios.module.scss";
import { toast } from "react-toastify";
import axios from "axios";
import React, { useState } from 'react'
import Loader from "../../loader/Loader";

const Batch = () => {
const [token, setToken] = useState([]);
const [isLoading, setIsLoading] = useState(false);
const FetchData = () => {
  setIsLoading(true);

const params = JSON.stringify({

  "email": 'fanninson@yahoo.co.uk',
  
  "password": '2928b1fb27fc4d94a4a324db5b20a295',
  
  });
axios.post('https://developers.cjdropshipping.com/api2.0/v1/authentication/getAccessToken', params,{

  "headers": {
  
  "content-type": "application/json",
  
  },
  
  })
.then(res => {
  setToken(res.data.data);
  setIsLoading(false);
  const docRef = addDoc(collection(db, "token"), {
    id:1,
    openId: res.data.data.openId,
    accessToken: res.data.data.accessToken,
    accessTokenExpiryDate: res.data.data.accessTokenExpiryDate,
    refreshToken: res.data.data.refreshToken,
    refreshTokenExpiryDate: res.data.data.refreshTokenExpiryDate,
    createDate: res.data.data.createDate,
    createdAt: Timestamp.now().toDate(),
  });
}).then(res => {
  toast.success("Token uploaded successfully.");
  setIsLoading(false);
}).catch(err => {
  toast.success(err.message);
  setIsLoading(false);
});
;
}

  
  return (
    <>
    {isLoading && <Loader />}
     <div>
      <button className="--btn --btn-primary" onClick={() => FetchData()}>Refresh Token</button>
    </div>
    <br/>
    <table>
        <tbody>
            <tr>
                <td>id: {token.id}</td>
            </tr>
            <tr>
                <td>openId: {token.openId}</td>
            </tr>
            <tr>
                <td>accessToken: {token.accessToken}</td>
            </tr>
            <tr>
                <td>accessTokenExpiryDate: {token.accessTokenExpiryDate}</td>
            </tr>
            <tr>
                <td>refreshToken: {token.refreshToken}</td>
            </tr> 
            <tr>
                <td>refreshTokenExpiryDate: {token.refreshTokenExpiryDate}</td>
            </tr>   
            <tr>
                <td>createDate: {token.createDate}</td>
            </tr>   
            <tr>
                <td>createdAt: {token.createdAt}</td>
            </tr>     
        </tbody>
     </table>
    </>
   
  )
}

export default Batch





