import React from "react";
// Sections

import Services from "../../components/Sections/Services";
import Contact from "../../components/Sections/Contact";

import Pricing from "../../components/Sections/Pricing";
import Projects from "../../components/Sections/Projects";
import Header from "../../components/Sections/Header";
import Welcome from "../../components/Sections/Welcome";


export default function Landing() {
  return (
    <>
      <Welcome />
      <Projects />
      <Services />
      <Pricing />
      <Contact />
    
    </>
  );
}


