import styles from "./ProductDetails.module.scss";

import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import spinnerImg from "../../../assets/spinner.jpg";

import { useDispatch, useSelector } from "react-redux";
import {
  ADD_TO_CART,
  CALCULATE_TOTAL_QUANTITY,
  CALCULATE_TOTAL_SHIPPING,
  DECREASE_CART,
  selectCartItems,
} from "../../../redux/slice/cartSlice";
import useFetchDocument from "../../../customHooks/useFetchDocument";
import useFetchCollection from "../../../customHooks/useFetchCollection";
import Card from "../../card/Card";
import StarsRating from "react-star-rate";
//import useFetchInventory from "../../../customHooks/useFetchInventory";
import { toast } from "react-toastify";
import axios from "axios";

const ProductDetails = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [vari, setVari] = useState(0);
  const [ship, setShip] = useState(0.00);
  const [inv, setInv] = useState("");
  const dispatch = useDispatch();
  const cartItems = useSelector(selectCartItems);
  const { document } = useFetchDocument(id);
  //const { shipping } = useFetchShipping();
  const { data } = useFetchCollection("reviews");
  const filteredReviews = data.filter((review) => review.productID === id);

  const cart = cartItems.find((cart) => cart.pid === id);
  const isCartAdded = cartItems.findIndex((cart) => {
    return cart.pid === id;
  });
  
  const getAll = (ID) => {
    axios
     .get(`https://developers.cjdropshipping.com/api2.0/v1/product/stock/queryByVid?vid=${ID}`, {
       headers: {
         "CJ-Access-Token": 'eyJhbGciOiJIUzI1NiJ9.eyJqdGkiOiIxMDYxNyIsInR5cGUiOiJBQ0NFU1NfVE9LRU4iLCJzdWIiOiJicUxvYnFRMGxtTm55UXB4UFdMWnltWWFTSmh4VGM3RHdYOHpKRStmN2pKZGNvZUdWMDVwb3o4OHBsQW1BU3VIMG9HWnVnREIzOEFQbEUvcmZDMktuWktsNi84Qk5VQndtL0FiYWxHMk9IZ05aUHNrODF4TVlaRm9LTG9GblF5WDFPSHpYMlZuWHBFMlp1dHJjalI1V1QrS3hEb0xpZ2ZpWElrNHhORUxoMlRrSFpDaVArN3UzNmVGb3lhVlMwSEJYSTRRQUp0bk5DTk5vdTVpZStYYUVvM24wMUxadkU4RDFobWVaMW5LVlNDczV1cEpTQzFjVTF3WDJEbXJWd3J3cU1ZNWpDUUxLa2dtWDRHdXZqOURXZng4UW81dFp6Q1UrT1dSY3M3NGJsQzJFMmRHa2Qva3FVYjVxK3hPazBKciJ9.wvWxle-rAEaKBKHb2CkaHMk5BTqslrNRKV1jd2DXXCU'
       }   
     })
     .then(res => {
      // setInv(res?.data.data[0]);
     return axios.post(`https://developers.cjdropshipping.com/api2.0/v1/logistic/freightCalculate`,{
       "startCountryCode": res?.data?.data[0]?.countryCode,
       "endCountryCode": "US",
       "products": [
           {
               "quantity": 1,
               "vid": res?.data.data[0].vid
           }
       ]
   }, {
       headers: {
         "Content-Type": "application/json",
         "CJ-Access-Token": 'eyJhbGciOiJIUzI1NiJ9.eyJqdGkiOiIxMDYxNyIsInR5cGUiOiJBQ0NFU1NfVE9LRU4iLCJzdWIiOiJicUxvYnFRMGxtTm55UXB4UFdMWnltWWFTSmh4VGM3RHdYOHpKRStmN2pKZGNvZUdWMDVwb3o4OHBsQW1BU3VIMG9HWnVnREIzOEFQbEUvcmZDMktuWktsNi84Qk5VQndtL0FiYWxHMk9IZ05aUHNrODF4TVlaRm9LTG9GblF5WDFPSHpYMlZuWHBFMlp1dHJjalI1V1QrS3hEb0xpZ2ZpWElrNHhORUxoMlRrSFpDaVArN3UzNmVGb3lhVlMwSEJYSTRRQUp0bk5DTk5vdTVpZStYYUVvM24wMUxadkU4RDFobWVaMW5LVlNDczV1cEpTQzFjVTF3WDJEbXJWd3J3cU1ZNWpDUUxLa2dtWDRHdXZqOURXZng4UW81dFp6Q1UrT1dSY3M3NGJsQzJFMmRHa2Qva3FVYjVxK3hPazBKciJ9.wvWxle-rAEaKBKHb2CkaHMk5BTqslrNRKV1jd2DXXCU'
       }   
     })
     })  
     .then(res2 => {
       setShip(res2?.data?.data[0]?.logisticPrice)
     })
     .catch(error => {});
    }
 
  useEffect(() => {
    setProduct(document)
  }, [document]);

  useEffect(() => {
    getAll(document?.variants[vari]?.vid)
  });

  const varChooce = (robo) => {
    setVari(robo)
  };
  
  const Allvarianto = document?.variants;
  const varianto = document?.variants[vari];
  const variantoS = [{...varianto, ship}];
  //const { inventory } = useFetchInventory(varianto?.vid);

 const addToCart = (varianto) => {
  dispatch(ADD_TO_CART(variantoS[0]));
  dispatch(CALCULATE_TOTAL_QUANTITY());
  dispatch(CALCULATE_TOTAL_SHIPPING());
};
const decreaseCart = (varianto) => {
  dispatch(DECREASE_CART(variantoS[0]));
  dispatch(CALCULATE_TOTAL_QUANTITY());
  dispatch(CALCULATE_TOTAL_SHIPPING());
};
  return (
    <section>
      <div className={`container ${styles.product}`}>
        <h2>Product Details</h2>
        <div>
          <Link to="/catalogue">&larr; Back To Products</Link>
        </div>
        {product === null ? (
          <img src={spinnerImg} alt="Loading" style={{ width: "50px" }} />
        ) : (
          <>
            <div className={styles.details}>
              <div className={styles.img}>
                <img src={varianto.variantImage} alt={varianto.variantNameEn} />
              </div>
              <div className={styles.content} >
                <h3>{varianto.variantNameEn}</h3>
                <h2 className={styles.price}>{`$${varianto.variantSellPrice}`}</h2>
                <p>
                  <b>SKU: </b> {varianto.variantSku}
                </p>
                <p>
                  <b>Material: </b> {document.materialNameEn}
                </p>
                <p>
                  <b>Packing Set:</b> {document.packingNameEnSet[0]}
                </p>
                <p>
                  <b>Shipping:</b> {ship}
                </p>
                <p>
                  <b>Shipping From:</b> 00
                </p>
                <p>
                  <b>Category:</b> {document.categoryName}
                </p>
                <p>
                  <b>Standard:</b> {varianto.variantStandard}
                </p>
                <p>
                  <b>Parking Weight:</b> {varianto.variantWeight}g
                </p>
                <p>
                  <b>Type:</b> {document.productProEn}
                </p>
      
                <div className={styles.count}>
                  {isCartAdded < 0 ? null : (
                    <>
                      <button
                        className="--btn"
                        onClick={() => decreaseCart(varianto)}
                      >
                        -
                      </button>
                      <p>
                        <b>{cart.cartQuantity}</b>
                      </p>
                      <button
                        className="--btn"
                        onClick={() => addToCart(varianto)}
                      >
                        +
                      </button>
                    </>
                  )}
                </div>
                <p><b>Variants</b></p>
                <div className={styles.variants}>
                {Allvarianto.map((varient, index) => {
                  return (
                      <input key={index} type="image"
                      className={styles.varspace}
                      onClick={() => varChooce(index)} src={varient.variantImage} alt={varient.variantNameEn} heigth="70px" width="70px"/>
                  );
                 })}
    
                </div>
                <button
                  className="--btn --btn-danger"
                  onClick={() => addToCart(varianto)}
                >
                  ADD TO CART
                </button>
              </div>
            </div>

        <div className={styles.hline} ><h3>Detail Description</h3></div>
        <div className={styles.content} >
        <p dangerouslySetInnerHTML={{__html: product.description}}></p>
        </div>
          </>
        )}
  
        <Card cardClass={styles.card}>
          <h3>Product Reviews</h3>
          <div>
            {filteredReviews.length === 0 ? (
              <p>There are no reviews for this product yet.</p>
            ) : (
              <>
                {filteredReviews.map((item, index) => {
                  const { rate, review, reviewDate, userName } = item;
                  return (
                    <div key={index} className={styles.review}>
                      <StarsRating value={rate} />
                      <p>{review}</p>
                      <span>
                        <b>{reviewDate}</b>
                      </span>
                      <br />
                      <span>
                        <b>by: {userName}</b>
                      </span>
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </Card>
      </div>
    </section>
  );
};

export default ProductDetails;
