import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
  ADD_TO_CART,
  CALCULATE_TOTAL_QUANTITY,
} from "../../../redux/slice/cartSlice";
import Card from "../../card/Card";
import styles from "./ProductItem.module.scss";

const ProductItem = ({ product, grid, pid, productNameEn, sellPrice, categoryName
  , productImage }) => {
  // const doc = useFetchDocument(pid);  
  const dispatch = useDispatch();
  const shortenText = (text, n) => {
    if (text.length > n) {
      const shortenedText = text.substring(0, n).concat("...");
      return shortenedText;
    }
    return text;
  };

  const addToCart = (product) => {
    dispatch(ADD_TO_CART(product));
    dispatch(CALCULATE_TOTAL_QUANTITY());
  };

  return (
    <Card cardClass={grid ? `${styles.grid}` : `${styles.list}`}>
      <Link to={`/product-details/${pid}`}>
        <div className={styles.img}>
          <img src={productImage} alt={productNameEn} />
        </div>
      </Link>
      <div className={styles.content}>
        <div className={styles.details}>
          <p>{`$${sellPrice}`}</p>
          <h4>{shortenText(productNameEn, 38)}</h4>
          <h6>{shortenText(categoryName, 18)}</h6>
        </div>
        {!grid && <p className={styles.desc}>{shortenText(categoryName, 200)}</p>}
        <Link to={`/product-details/${pid}`}>
          <button className="--btn --btn-danger"> Detail</button>
        </Link>
      </div>
    </Card>
  );
};

export default ProductItem;
